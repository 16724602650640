$beige: #f7edb7;
$dark-grey: #272f30;
$orange: #e0401c;
$red: #9e2b20;
$warm-white: #fffcef;
$light-blue: #d5fffc;
$dull-blue: #437299;
$base-font: Oxygen, Arial, Helvetica, sans-serif;
$heading-font: Oswald, Arial, Helvetica, sans-serif;

/* * {
    transition: all .5s;
} */

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.element-hidden {
  display: none;
}

.plan-name,
.plan-price,
.plan-details,
.button,
.more-options,
.best-choice {
  padding: 10px;
}

.plans {
  margin: 0 auto;
  max-width: 80%;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  .plan-box {
    display: flex;
    width: 33.3333%;
    background: $warm-white;
    margin: 10px 0;
    min-height: 100px;
    .best-choice {
      background: $light-blue;
      text-transform: uppercase;
      font-weight: 600;
      color: $dull-blue;
      box-shadow: -3px 0 3px rgba(0, 0, 0, 0.15), 3px 0 3px rgba(0, 0, 0, 0.15);
      margin-top: auto;
    }
    &:hover {
      margin-top: -15px;
      background: white;
      @media all and (max-width: 700px) {
        margin-top: 10px;
      }
      .button {
        //padding: 20px;
        //font-size: 30px;
      }
      .plan-name {
        color: black;
      }
      .plan-price {
        background: saturate($red, 55%);
        box-shadow: -3px 0 3px rgba(0, 0, 0, 0.15),
          3px 0 3px rgba(0, 0, 0, 0.15);
        text-align: center;
      }
      .more-options {
        display: block;
        padding: 20px;
        text-align: right;
        position: relative;
        .additional-options {
          position: relative;
          text-align: left;
          width: 100%;
          margin: 0;
        }
        a {
          color: $red;
          text-decoration: none;
        }
      }
    }
    @media all and (max-width: 700px) {
      &:first-child {
        margin-bottom: 20px;
      }
    }
    &:last-child {
      //margin-right: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
      box-shadow: -3px 0 3px rgba(0, 0, 0, 0.15), 3px 0 3px rgba(0, 0, 0, 0.15);
      position: relative;
      &:hover {
        margin-top: -35px;
        @media all and (max-width: 700px) {
          margin-top: 0px;
          marign-right: -30px;
        }
      }
    }
    @media all and (max-width: 700px) {
      width: 100%;
    }
  }
  .plan-name {
    font: 300 32px $heading-font;
    text-align: center;
    margin: 0;
    padding: 20px;
  }
  .plan-price {
    background: $red;
    color: white;
    display: flex;
    font: 24px $heading-font;
    padding: 20px 10px;
    text-align: center;
    strong {
      font-size: 34px;
      align-self: baseline;
      line-height: 1;
      font-weight: 500;
    }
  }
  .plan-options {
    display: flex;
    flex-flow: column;
    width: 100%;
    li {
      border-bottom: 1px solid #cdcdcd;
      padding: 5px 0;
      margin: 5px 0;
    }
    .button {
      margin-top: auto;
      display: block;
      background: $orange;
      color: white;
      text-align: center;
      font: 24px $heading-font;
      text-decoration: none;
      &:hover {
        background: saturate($orange, 10%);
      }
    }
  }
}
