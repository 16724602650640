.button_container {
  position  : absolute;
  top       : -55px;
  left      : 40px;
  height    : 27px;
  width     : 35px;
  cursor    : pointer;
  z-index   : 101;
  transition: opacity 0.25s ease;
}

.button_container:hover {
  opacity: 0.7;
}

.button_container.active .top {
  transform : translateY(11px) translateX(0) rotate(45deg);
  background: #fff;
}

.button_container.active .middle {
  opacity   : 0;
  background: #fff;
}

.button_container.active .bottom {
  transform : translateY(-11px) translateX(0) rotate(-45deg);
  background: #fff;
}

.button_container span {
  background: lightgrey;
  border    : none;
  height    : 5px;
  width     : 100%;
  position  : absolute;
  top       : 0;
  left      : 0;
  transition: all 0.35s ease;
  cursor    : pointer;
}

.button_container span:nth-of-type(2) {
  top: 11px;
}

.button_container span:nth-of-type(3) {
  top: 22px;
}

.overlay {
  position  : fixed;
  background: #3b807f;
  top       : 0;
  left      : 0;
  width     : 100%;
  height    : 0%;
  opacity   : 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow  : hidden;
}

.overlay.open {
  opacity   : 0.9;
  visibility: visible;
  height    : 100%;
  z-index   : 100;

  transform          : translatez(0);
  backface-visibility: hidden;
  will-change        : transform;
  transform          : translate3d(0);
}

.overlay.open li {
  animation      : fadeInRight 0.5s ease forwards;
  animation-delay: 0.35s;
}

.overlay.open li:nth-of-type(2) {
  animation-delay: 0.4s;
}

.overlay.open li:nth-of-type(3) {
  animation-delay: 0.45s;
}

.overlay.open li:nth-of-type(4) {
  animation-delay: 0.5s;
}

.overlay nav {
  position      : relative;
  height        : 70%;
  top           : 50%;
  transform     : translateY(-50%);
  font-size     : 50px;
  font-family   : "Oswald", sans-serif;
  text-transform: uppercase;
  font-weight   : 400;
  text-align    : center;
}

.overlay ul {
  list-style: none;
  padding   : 0;
  margin    : 0 auto;
  display   : inline-block;
  position  : relative;
  height    : 100%;
}

.overlay ul li {
  display   : block;
  height    : 25%;
  height    : calc(100% / 4);
  min-height: 50px;
  position  : relative;
  opacity   : 0;
}

.overlay ul li a {
  display        : block;
  position       : relative;
  color          : #fff;
  text-decoration: none;
  overflow       : hidden;
}

.overlay ul li a:hover:after,
.overlay ul li a:focus:after,
.overlay ul li a:active:after {
  width: 100%;
}

.overlay ul li a:after {
  content   : "";
  position  : absolute;
  bottom    : 0;
  left      : 50%;
  width     : 0%;
  transform : translateX(-50%);
  height    : 3px;
  background: #fff;
  transition: 0.35s;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left   : 20%;
  }

  100% {
    opacity: 1;
    left   : 0;
  }
}

/* Logo */
.main-logo {
  display   : inline-block;
  position  : absolute;
  background: lightgrey;
  left      : 120px;
  top       : -90px;
  width     : 180px;
  height    : 180px;
  z-index   : 1;
}

.secondary-logo {
  display: none;
}

@media screen and (max-width: 598px) {
  .main-logo {
    display: none;
  }

  .secondary-logo {
    display: block;
    width: 170px;
    position: absolute;
    top: -60px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}
}

.main-logo img {
  position : absolute;
  left     : 50%;
  top      : 50%;
  transform: translate(-50%, -50%);
  max-width: 70%;
}