.Slider {
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Slider-slides {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(12, 115, 132, 0.5);
    background-blend-mode: multiply;
  }
}

.Slider-slides--transition {
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.Slider-slides > * {
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.Slider-slides > * {
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.Slider-nav {
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 10px;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

.Slider-navButton {
  background: none;
  border: 0;
  height: 20px;
  opacity: 0.5;
  outline: none;
  position: relative;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  width: 20px;
  padding: 0;
}

.Slider-navButton::after {
  background: #345;
  border-radius: 50%;
  content: "";
  display: block;
  height: 6px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 6px;
}

.Slider-navButton--active {
  opacity: 1;
}

.Slider-navButton--active::after {
  height: 12px;
  left: 4px;
  opacity: 1;
  top: 4px;
  width: 12px;
}

.Slider-arrows {
  height: 0;
  left: 0;
  position: absolute;
  top: calc(50% - 35px);
  width: 100%;
  z-index: 1;
}

.Slider-arrows--noNav {
  top: calc(50% - 20px);
}

.Slider-arrow {
  background: none;
  border: none;
  height: 40px;
  opacity: 0.5;
  outline: none;
  position: absolute;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  padding: 0px;
  width: 21px;
}

.Slider-arrow:hover {
  opacity: 1;
}

.Slider-arrow::after,
.Slider-arrow::before {
  background: #fff;
  border-radius: 3px;
  content: "";
  display: block;
  height: 23px;
  position: absolute;
  width: 5px;
  left: 8px;
}

.Slider-arrow::before {
  top: 0;
}

.Slider-arrow::after {
  bottom: 0;
}

.Slider-arrow--left {
  top: 0;
  left: 10px;
}

.Slider-arrow--left::before {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.Slider-arrow--left::after {
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.Slider-arrow--right {
  top: 0;
  right: 10px;
}

.Slider-arrow--right::before {
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.Slider-arrow--right::after {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.slider-content {
  position: absolute;
  top: 50%;
  left: 120px;
  transform: translateY(-50%);
  font-size: 150%;
  line-height: 0.5;
  text-shadow: 2px 2px 4px black;
  color: #fff;
  z-index: 1;
  @media screen and (max-width: 580px) {
    font-size: 100%;
    left: 60px;
  }
  h2 {
    font-size: 160%;
    margin-bottom: 0;
    @media screen and (max-width: 580px) {
      font-size: 120%;
    }
  }
  p {
    background: rgb(12, 115, 132);
    padding: 20px;
    display: inline-block;
  }
}
